import { Owner } from '../customers/customerTypes';
import { Device, DeviceStatus } from '../devices/deviceTypes';

export interface WebSocketStore {
  connected: boolean;
}

export interface WSStore {
  getState: any;
  dispatch: any;
  host?: string;
}

export enum WSSource {
  FACILITY = 'facility',
  CUSTOMER_CARE = 'customer_care',
  ENG = 'eng',
  SERVER = 'server',
  FACILITY_ADMIN = 'facility_admin',
}

export enum WSType {
  COMMAND = 'command',
  RESPONSE = 'response',
  EVENT = 'event',
}

export enum WSName {
  GET_CUSTOMER_LIST = 'get_customer_list',
  GET_FACILITY_CUSTOMERS = 'get_facility_customers',
  UPDATE_STATUS = 'update_status',
  GET_DEVICE_LIST = 'get_device_list',
  DEVICE_STATUS = 'device_status',
  REQUEST_LOG = 'request_log',
  DEVICE_UPDATE = 'device_update',
  RELOAD_SW_VERSIONS = 'reload_sw_versions',
}

export interface WSPayload {
  source: WSSource;
  type: WSType;
  name: WSName;
  status: number;
  connStatus: 'online' | 'offline';
  customer_id: number;
  serial_number: string;
  device_serial_number: string;
  device_status: DeviceStatus;
  customer_list: Owner[];
  error: any;
  device_list: Device[];
  upload_status: number;
  upload_response: string;
  customers: Owner[];
}

export interface Target {
  url: string;
}

export interface WSEvent {
  data: string;
  target: Target;
}

export interface WSMessage {
  source: WSSource;
  type: WSType;
  name: WSName;
  sync_code: string;
  patient_customer_id: number;
  serial_number: string;
  note: string;
  version: string;
}

export interface WSActionPayload {
  host: string;
  token: string;
  msg: WSMessage;
}

export interface WSAction {
  type: string;
  payload: WSActionPayload;
}

export enum WebSocketStoreActionTypes {
  WS_CONNECTED = 'WS_CONNECTED',
  WS_CONNECT = 'WS_CONNECT',
  WS_CONNECTING = 'WS_CONNECTING',
  WS_DISCONNECT = 'WS_DISCONNECT',
  WS_DISCONNECTED = 'WS_DISCONNECTED',
  WS_SEND = 'WS_SEND',
}

export type WsConnected = {
  type: WebSocketStoreActionTypes.WS_CONNECTED;
  payload: { host: string };
};

export type WsConnect = {
  type: WebSocketStoreActionTypes.WS_CONNECT;
  payload: { host: string; token: string };
};

export type WsConnecting = {
  type: WebSocketStoreActionTypes.WS_CONNECTING;
  payload: { host: string };
};

export type WsDisconnect = {
  type: WebSocketStoreActionTypes.WS_DISCONNECT;
  payload: { host: string };
};

export type WsDisconnected = {
  type: WebSocketStoreActionTypes.WS_DISCONNECTED;
  payload: { host: string };
};

export type WebSocketAction =
  | WsConnected
  | WsConnect
  | WsConnecting
  | WsDisconnect
  | WsDisconnected;
