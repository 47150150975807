import axios from 'axios';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { restHost, storageHost } from '../../apiConfig';
import { deleteToken, formatUrl, getCookie } from '../../shared/utils';
import { REACT_ERROR_CODES } from '../../shared/constants';
import {
  WebSocketStoreActionTypes,
  WSName,
  WSSource,
  WSType,
} from '../webSocket/webSocketTypes';
import {
  Device,
  DeviceActionTypes,
  DeviceLog,
  DevicesStore,
  DeviceStatus,
  Log,
  SeamImage,
  ShadowConfig,
  StoreDeviceLogs,
  BBImageSetMetadata,
  DeviceSyncPayload,
} from './deviceTypes';

export const populateDevices = (deviceList: Device[]) => ({
  type: DeviceActionTypes.POPULATE_DEVICES,
  payload: { deviceList },
});

export const clearDeviceList = () => ({
  type: DeviceActionTypes.CLEAR_DEVICE_LIST,
});

export const updateDeviceStatus = (
  deviceSerialNumber: string,
  deviceStatus: DeviceStatus
) => ({
  type: DeviceActionTypes.UPDATE_DEVICE_STATUS,
  payload: { deviceSerialNumber, deviceStatus },
});

export const updateSelectedDeviceStatus = (status: string) => ({
  type: DeviceActionTypes.UPDATE_SELECTED_DEVICE_STATUS,
  payload: { status },
});

export const getDeviceList = (
  facility_assigned?: boolean,
  owner_assigned?: boolean
) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `devices`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
        params: {
          facility_assigned,
          owner_assigned,
        },
      })
      .then((res) => {
        dispatch(setLoadState(false));
        dispatch(populateDevices(res.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          deleteToken();
        }
        console.error(err);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load device log');
      });
  };
};

export const syncDevice = (data: DeviceSyncPayload) => {
  return async (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    try {
      const response = await axios.post(
        formatUrl(restHost, `devices/validate_sync_code?assign=true`),
        data,
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );
      if (response.request?.status === 400) {
        dispatch(apiError('Invalid sync code'));
        dispatch(
          failureSyncDevice({
            error_code: REACT_ERROR_CODES.INVALID_SYNC_CODE,
            error_message: 'Invalid sync code',
          })
        );
        dispatch(setLoadState(false));
      }

      if (
        response.request?.status === 403 ||
        response.request?.status === 500
      ) {
        dispatch(apiError('Device assignment failed'));
        dispatch(
          failureSyncDevice({
            error_code: REACT_ERROR_CODES.INVALID_PARAM,
            error_message: 'Device assignment failed!',
          })
        );
        dispatch(setLoadState(false));
      }

      if (response.request?.status === 200) {
        toast.success('Device added successfully!');
        dispatch(didSyncDevice());
        dispatch(setLoadState(false));
      }
    } catch (error: any) {
      if (error.request) {
        console.log('request error');
      } else if (error.response) {
        console.log('response error');
      }
    }
  };
};

export const failureSyncDevice = (error: any) => ({
  type: DeviceActionTypes.FAILURE_SYNC_DEVICE,
  payload: { syncDeviceError: error },
});

export const didSyncDevice = () => ({
  type: DeviceActionTypes.DID_SYNC_DEVICE,
});

export const clearSyncDeviceStatus = () => ({
  type: DeviceActionTypes.CLEAR_SYNC_DEVICE_STATE,
});

export const requestDeviceLog = (serial_number: string, note: string) => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.CUSTOMER_CARE,
      type: WSType.COMMAND,
      name: WSName.REQUEST_LOG,
      serial_number: serial_number,
      note: note,
    },
  },
});

export const deviceReboot = (device_id: number) => {
  return async (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    try {
      const res = await axios.post(
        formatUrl(restHost, `devices/${device_id}/sys_admin`),
        { action: 'reboot' },
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );

      if ((res as any).response && (res as any).response.status === 500) {
        throw new Error();
      } else if (
        (res as any).response &&
        (res as any).response.status === 503
      ) {
        toast.error('Device is offline. Try again later.');
      } else {
        toast.success('Device reboot command sent successfully!');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        deleteToken();
      }
      toast.error('Failed to send device reboot command');
      dispatch(apiError(error));
    } finally {
      dispatch(setLoadState(false));
    }
  };
};

export const reloadSoftwareVersions = () => ({
  type: WebSocketStoreActionTypes.WS_SEND,
  payload: {
    msg: {
      source: WSSource.ENG,
      type: WSType.COMMAND,
      name: WSName.RELOAD_SW_VERSIONS,
    },
  },
});

export const getDeviceLogByUrl = (logUrl: URL) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(logUrl.href, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeLog(res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load device log');
      });
  };
};

export const getDeviceLog = (logId: number) => {
  return getDeviceLogByUrl(
    new URL(formatUrl(storageHost, `device/log/${logId}`))
  );
};

export const apiError = (error: any) => ({
  type: DeviceActionTypes.DEVICES_API_ERR,
  payload: { error: error },
});

export const setLoadState = (loadState: boolean) => ({
  type: DeviceActionTypes.DEVICES_SET_LOAD_STATE,
  payload: { isLoading: loadState },
});

export const storeLog = (log: DeviceLog[]) => ({
  type: DeviceActionTypes.STORE_LOG,
  payload: { log: log },
});

export const clearLog = () => ({
  type: DeviceActionTypes.CLEAR_LOG,
});

export const updateDeviceProperties = (
  device_id: number,
  fields: Partial<Device>
) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, `devices/${device_id}`), fields, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        dispatch(setLoadState(false));
        dispatch(getDeviceList());
        toast.success(`Device updated`);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to update device');
      });
  };
};

export const storeSeamImages = (
  deviceId: number,
  seamImages: {
    [deviceId: string]: SeamImage[];
  }
) => ({
  type: DeviceActionTypes.STORE_SEAM_IMAGES,
  payload: { deviceId, seamImages: seamImages },
});

export const clearSeamImages = () => ({
  type: DeviceActionTypes.CLEAR_SEAM_IMAGES,
});

export const fetchSeamImages = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'devices/' + deviceId + '/dispenser_images'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeSeamImages(deviceId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error loading seam images');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const storeDeviceShadow = (
  deviceId: number,
  shadowConfigs: ShadowConfig[]
) => ({
  type: DeviceActionTypes.STORE_DEVICE_SHADOW,
  payload: { deviceId, shadowConfigs },
});

export const fetchDeviceShadow = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'devices/' + deviceId + '/shadow'), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        const shadowState = res.data;
        const configFieldNames =
          shadowState &&
          Array.from(
            new Set([
              ...Object.keys(shadowState.reported),
              ...Object.keys(shadowState.desired),
            ])
          );
        const configs =
          configFieldNames &&
          configFieldNames.map((configName: string) => ({
            configName,
            reportedValue: shadowState.reported[configName],
            desiredValue: shadowState.desired[configName],
          }));
        dispatch(storeDeviceShadow(deviceId, configs));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error getting device shadow');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const clearDeviceShadow = () => ({
  type: DeviceActionTypes.CLEAR_DEVICE_SHADOW,
});

export const updateDeviceShadow = (device_id: number, stateUpdate: any) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .put(formatUrl(restHost, `devices/${device_id}/shadow`), stateUpdate, {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then(() => {
        toast.success(`Device Shadow Updated`);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          deleteToken();
        }
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to update device shadow');
      });
  };
};

export const storeDeviceLogs = (
  deviceId: number,
  logs: Log[]
): StoreDeviceLogs => ({
  type: DeviceActionTypes.STORE_DEVICE_LOGS,
  payload: { deviceId, deviceLogs: logs },
});

export const fetchDeviceLogs = (deviceId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, `devices/${deviceId}/logs`), {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(storeDeviceLogs(deviceId, res.data));
        dispatch(setLoadState(false));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          deleteToken();
        }
        toast.error('Error loading seam images');
        dispatch(apiError(err));
        dispatch(setLoadState(false));
      });
  };
};

export const storeBBImages = (bbImages: BBImageSetMetadata[]) => ({
  type: DeviceActionTypes.STORE_BB_IMAGES,
  payload: { bbImages },
});

export const getBoundingBoxImages = (startDate?: DateTime) => {
  return (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    return axios
      .get(formatUrl(restHost, 'eng/bounding_box_images'), {
        params: {
          start_date: startDate ? startDate.toISODate() : undefined,
        },
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      })
      .then((res) => {
        dispatch(setLoadState(false));
        dispatch(storeBBImages(res.data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          deleteToken();
        }
        console.error(err);
        dispatch(apiError(err));
        dispatch(setLoadState(false));
        toast.error('Failed to load bounding box images');
      });
  };
};

export const unassignDevice = (device_id: number) => {
  return async (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    try {
      const res = await axios.post(
        formatUrl(restHost, `devices/${device_id}/sys_admin`),
        { action: 'factory_reset' },
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );

      if ((res as any).response && (res as any).response.status === 500) {
        throw new Error();
      } else if (
        (res as any).response &&
        (res as any).response.status === 503
      ) {
        toast.error('Device is offline. Try again later.');
      } else {
        toast.success('Device factory reset command sent successfully!');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        deleteToken();
      }
      toast.error('Failed to send device factory reset command');
      dispatch(apiError(error));
    } finally {
      dispatch(setLoadState(false));
    }
  };
};

export const deleteDatabase = (device_id: number) => {
  return async (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    try {
      const res = await axios.post(
        formatUrl(restHost, `devices/${device_id}/sys_admin`),
        { action: 'delete_hub_db' },
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );

      if ((res as any).response && (res as any).response.status === 500) {
        throw new Error();
      } else if (
        (res as any).response &&
        (res as any).response.status === 503
      ) {
        toast.error('Device is offline. Try again later.');
      } else {
        toast.success('Device database deletion command sent successfully!');
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        deleteToken();
      }
      toast.error('Failed to send device database deletion command');
      dispatch(apiError(error));
    } finally {
      dispatch(setLoadState(false));
    }
  };
};

export const fetchDeviceById = (deviceId: number) => {
  return async (
    dispatch: ThunkDispatch<DevicesStore, void, AnyAction> & Dispatch
  ) => {
    dispatch(setLoadState(true));
    try {
      const response = await axios.get(
        formatUrl(restHost, `devices/${deviceId}`),
        {
          headers: {
            Authorization: `Bearer ${getCookie('access_token')}`,
          },
        }
      );
      dispatch({
        type: DeviceActionTypes.FETCH_DEVICE_BY_ID,
        payload: { device: response.data },
      });
      dispatch(setLoadState(false));
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        deleteToken();
      }
      dispatch(apiError(err));
      dispatch(setLoadState(false));
      toast.error('Failed to fetch device by ID');
    }
  };
};
