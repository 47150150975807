import { Button, Chip, Container, Grid, Typography } from '@mui/material';
import { isNil, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { SOFTWARE_CHANNEL_ID } from '../../shared/constants';
import { getPrivilegeById } from '../../shared/utils';
import { RootState } from '../../store';
import { DashboardUser } from '../../store/dashboardUser/dashboardUserTypes';
import {
  clearSeamImages,
  deviceReboot,
  fetchDeviceLogs,
  fetchDeviceShadow,
  clearDeviceShadow,
  // fetchSeamImages,
  getDeviceList,
  updateDeviceProperties,
} from '../../store/devices/actionCreators';
import {
  Device,
  SeamImage,
  Log,
  ShadowConfig,
} from '../../store/devices/deviceTypes';
import { getSoftwareVersions } from '../../store/software/actionCreators';
import { SoftwareVersion } from '../../store/software/softwareTypes';
import LoadingSpinner from '../Shared/LoadingSpinner';
import Modal from '../Shared/Modal';
import DeviceLogsTable from './components/DeviceLogsTable';
import DeviceUpdateButton from './components/DeviceUpdateButton';
import RequestDeviceLog from './components/RequestDeviceLog';
import SeamImageTable from './components/SeamImageTable';
import UpdateDeviceChannel from './components/UpdateDeviceChannel';
import UnassignDevice from '../Shared/UnassignModal';
import DeleteDatabase from './components/DeleteDatabase';
import DeviceShadowsConfig from './components/DeviceShadowsConfig';

function DeviceDetail() {
  const {
    connected,
    deviceList,
    deviceLogs,
    seamImages,
    shadowConfigs,
    softwareVersions,
    dashboardUserInfo,
  } = useSelector<
    RootState,
    {
      connected: boolean;
      deviceList: Device[] | null;
      deviceLogs: Log[] | null;
      seamImages: SeamImage[] | null;
      shadowConfigs: ShadowConfig[] | null;
      softwareVersions: SoftwareVersion[] | null;
      dashboardUserInfo: DashboardUser | null;
    }
  >((state) => {
    const { webSocket, devices, software, dashboardUser } = state;
    const { connected } = webSocket;
    const { deviceList, seamImages, deviceLogs, shadowConfigs } = devices;
    const { softwareVersions } = software;
    const { dashboardUserInfo } = dashboardUser;
    return {
      connected,
      deviceList,
      deviceLogs,
      seamImages,
      shadowConfigs,
      softwareVersions,
      dashboardUserInfo,
    };
  });
  const dispatch = useDispatch();

  const { deviceSerialNumber = '' } = useParams<{
    deviceSerialNumber: string;
  }>();

  const [showDeviceLogRequestWindow, setLogRequestVisibility] = useState(false);
  const [showRebootConfirmation, setRebootConfirmationVisibility] =
    useState(false);
  const [
    showDeleteDatabaseConfirmation,
    setDeleteDatabaseConfirmationVisibility,
  ] = useState(false);
  const [showUnassignConfirmation, setUnassignConfirmationVisibility] =
    useState(false);

  const device =
    deviceList &&
    deviceList.find((device) => device.serial_number === deviceSerialNumber);

  useEffect(() => {
    if (isNull(deviceList)) {
      dispatch(getDeviceList());
    }
    if (isNull(softwareVersions)) {
      dispatch(getSoftwareVersions());
    }
    // These are dead code being initialized in https://admin.internal.omcare.com:3000/device/808080808080
    // commenting it for now as it can be re-used if we decided to integrate viewing bounding box image in admin dashboard
    // as most of code will be usable.

    // if (!isNil(device) && isNull(seamImages)) {
    //   dispatch(fetchSeamImages(device.id));
    // }
    if (!isNil(device) && isNull(deviceLogs)) {
      dispatch(fetchDeviceLogs(device.id));
    }
    if (!isNil(device) && isNull(shadowConfigs)) {
      dispatch(fetchDeviceShadow(device.id));
    }
  }, [
    connected,
    deviceList,
    deviceLogs,
    seamImages,
    shadowConfigs,
    softwareVersions,
    device,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearSeamImages());
      dispatch(clearDeviceShadow());
    };
  }, [dispatch]);

  const isEngineer =
    dashboardUserInfo &&
    getPrivilegeById(dashboardUserInfo.privilege_id) === 'engineering';

  const rebootDevice = () => {
    if (device) {
      dispatch(deviceReboot(device.id));
    }
  };

  const updateDevice = (
    e: React.MouseEvent,
    deviceId: number,
    versionId: number
  ) => {
    e.preventDefault();
    console.log('updating device: ' + deviceId + ' to version ' + versionId);
    dispatch(
      updateDeviceProperties(deviceId, {
        ...device,
        pinned_software_version_id: versionId,
      })
    );
  };

  const channelMap = Object.keys(SOFTWARE_CHANNEL_ID);

  if (isNull(deviceList)) {
    return <LoadingSpinner />;
  } else
    return (
      <Container maxWidth="lg">
        <Helmet>
          <title>Ōmcare - Device Details</title>
        </Helmet>
        {(() => {
          if (!device) {
            return (
              <div className="mt-3">
                <p>Device serial number {deviceSerialNumber} not found</p>
              </div>
            );
          }
          return (
            <>
              <Grid container>
                <Grid item xs>
                  <Typography>
                    <b>Owner:</b> {device.owner_name}
                  </Typography>
                  <Typography>
                    <b>Serial number:</b> {device.serial_number}
                  </Typography>
                  <Typography>
                    <b>HW version:</b> {device.hardware_version}
                  </Typography>
                  <Typography>
                    <b>SW version:</b> {device.software_version}
                  </Typography>
                  <Typography>
                    <b>Current software channel:</b>{' '}
                    {channelMap[device.software_channel_id - 1]}
                  </Typography>
                </Grid>
                <Grid item xs style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: 10,
                    }}
                  >
                    <Typography sx={{ marginRight: 5 }}>
                      <b>Status:</b>
                    </Typography>
                    <Chip
                      label={device.status.toUpperCase()}
                      color={device.status === 'online' ? 'primary' : 'default'}
                    />
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setLogRequestVisibility(true)}
                    >
                      Request log
                    </Button>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setRebootConfirmationVisibility(true)}
                    >
                      Reboot
                    </Button>
                  </div>
                  <DeviceUpdateButton
                    action={updateDevice}
                    softwareVersions={softwareVersions || []}
                    device={device}
                  />
                  {isEngineer ? <UpdateDeviceChannel device={device} /> : null}
                  <div style={{ marginBottom: 5 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setUnassignConfirmationVisibility(true)}
                      // OM-3539: Temporarily allow factory reset (device unassign) while owner is not assigned to device
                      // disabled={!device.customer_id}
                    >
                      Unassign device
                    </Button>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        setDeleteDatabaseConfirmationVisibility(true)
                      }
                    >
                      Delete database
                    </Button>
                  </div>
                </Grid>
              </Grid>

              <DeviceLogsTable logs={deviceLogs || ([] as Log[])} />

              <div style={{ margin: '20px 0px' }}>
                <SeamImageTable seamImages={seamImages} />
              </div>

              <div style={{ margin: '20px 0px' }}>
                <DeviceShadowsConfig
                  configs={shadowConfigs}
                  deviceId={device.id}
                />
              </div>
            </>
          );
        })()}
        {showDeviceLogRequestWindow && device ? (
          <RequestDeviceLog
            serialNumber={device.serial_number}
            onCancel={() => {
              setLogRequestVisibility(false);
            }}
            onSuccess={() => {
              setLogRequestVisibility(false);
            }}
          />
        ) : null}
        {showRebootConfirmation ? (
          <Modal
            onClose={() => setRebootConfirmationVisibility(false)}
            actions={{
              primaryAction: {
                onClick: () => {
                  rebootDevice();
                  setRebootConfirmationVisibility(false);
                },
                text: 'Reboot',
              },
            }}
          >
            <span>Are you sure you want to reboot this device?</span>
          </Modal>
        ) : null}
        {showUnassignConfirmation && device ? (
          <UnassignDevice
            deviceId={device.id}
            onCancel={() => setUnassignConfirmationVisibility(false)}
            onSuccess={() => setUnassignConfirmationVisibility(false)}
          />
        ) : null}
        {showDeleteDatabaseConfirmation && device ? (
          <DeleteDatabase
            deviceId={device.id}
            ownerName={device.owner_name}
            onCancel={() => setDeleteDatabaseConfirmationVisibility(false)}
            onSuccess={() => setDeleteDatabaseConfirmationVisibility(false)}
          />
        ) : null}
      </Container>
    );
}

export default withRouter(DeviceDetail);
