import { styled } from '@mui/system';
import { isEqual, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  ACCOUNT_DETAILS_ONLY,
  ADMIN_ACCOUNT_DETAILS_TABS,
  REACT_ERROR_CODES,
  SCHEDULER_ACCOUNT_DETAILS_TABS,
  UNIFIED_FACILITY_ADMIN_ROLES,
} from '../../../shared/constants';
import { formatPhoneNumber, getPrivilegeById } from '../../../shared/utils';
import { RootState } from '../../../store';
import {
  getCustomer,
  getCustomerCareTeam,
  getCustomerList,
  getFacilityCustomers,
  saveOwnerPin,
  sideBarTabSelection,
} from '../../../store/customers/actionCreators';
import { clearSyncDeviceStatus } from '../../../store/devices/actionCreators';
import { TabConfig } from '../../../store/GlobalTypes';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import SideTabs from '../../Shared/SideTabs';
import UnassignDevice from '../../Shared/UnassignModal';
import CaregiverDetailView from './CaregiverDetailView';
import DeviceDetails from './DeviceDetails';
import OwnerDetailCareTeam from './OwnerDetailCareTeam';
import OwnerDetailView from './OwnerDetailView';
import PinRequestModal from './PinRequestModal';
import SyncDeviceModal from './SyncDeviceModal';
import MedicationScheduleTable from './MedicationScheduleTable';

const Container = styled('div')(() => ({ display: 'flex' }));
const Content = styled('div')(() => ({ flexGrow: 1, margin: 50 }));

const stateSelector = (state: RootState) => {
  const { webSocket, dashboardUser, devices, customers } = state;
  const { connected } = webSocket;
  const { dashboardUserInfo } = dashboardUser;
  const { syncDeviceError, deviceSynced } = devices;
  const {
    careTeam,
    isLoading,
    selectedTab,
    facilityCustomers,
    currentOwnerPin,
  } = customers;
  return {
    connected,
    dashboardUserInfo,
    syncDeviceError,
    careTeam,
    isLoading,
    deviceSynced,
    selectedTab,
    facilityCustomers,
    currentOwnerPin,
  };
};

function CustomerDetail() {
  const { customer_id } = useParams<{ customer_id: string }>();
  const history = useHistory();

  const dispatch = useDispatch();

  const {
    careTeam,
    connected,
    dashboardUserInfo,
    isLoading,
    deviceSynced,
    facilityCustomers,
    selectedTab = ACCOUNT_DETAILS_ONLY.ACCOUNT_DETAILS,
    syncDeviceError,
    currentOwnerPin,
  } = useSelector<RootState, ReturnType<typeof stateSelector>>(stateSelector);

  const [show_sync_device_modal, setShowSyncDeviceModal] = useState(false);
  const [showPinRequestModal, setShowPinRequestModal] = useState(false);
  const [showUnassignConfirmation, setUnassignConfirmationVisibility] =
    useState(false);

  const customer = (facilityCustomers || []).find(
    (customer) => customer.customer_id === Number(customer_id)
  );

  useEffect(() => {
    if (!customer && !isLoading) {
      if (dashboardUserInfo) {
        if (
          getPrivilegeById(dashboardUserInfo.privilege_id) === 'customer_care'
        ) {
          dispatch(getCustomerList());
        } else {
          dispatch(getFacilityCustomers(dashboardUserInfo.facility_id ?? 0));
        }
      }
      dispatch(getCustomer(Number(customer_id)));
    }

    if (customer && customer.is_patient && isNull(careTeam)) {
      dispatch(getCustomerCareTeam(Number(customer_id)));
    }
    if (customer && dashboardUserInfo) {
      if (
        customer.customer_id === dashboardUserInfo.customer_id &&
        dashboardUserInfo &&
        UNIFIED_FACILITY_ADMIN_ROLES.includes(
          dashboardUserInfo.facility_role_id as number
        )
      ) {
        customer.pin = dashboardUserInfo.pin;
      }
    }
    if (!isNull(syncDeviceError)) {
      toast.error(syncDeviceErrorMessageHandler(syncDeviceError), {
        onClose: () => dispatch(clearSyncDeviceStatus()),
      });
    }
    if (deviceSynced) {
      dispatch(clearSyncDeviceStatus());
      setShowSyncDeviceModal(false);
      window.location.reload();
    }
  }, [
    connected,
    customer,
    dashboardUserInfo,
    syncDeviceError,
    facilityCustomers,
    deviceSynced,
    careTeam,
    dispatch,
    isLoading,
    customer_id,
  ]);

  const handleAddPrimaryContact = () => {
    history.push(`/customers/${customer_id}/create-primary-contact`);
  };

  const cancelModal = () => {
    setShowSyncDeviceModal(false);
    setShowPinRequestModal(false);
    setUnassignConfirmationVisibility(false);
    dispatch(saveOwnerPin(null));
  };

  if (customer) {
    customer.phone_number = formatPhoneNumber(
      customer.phone ? customer.phone : customer.phone2 ? customer.phone2 : ''
    );
    if (customer.address2) {
      customer.formatted_address = customer.address1 + ', ' + customer.address2;
    } else {
      customer.formatted_address = customer.address1;
    }
  }

  let syncDeviceErrorMessageHandler = function (error: {
    error_code: number;
    error_message: string;
  }) {
    let msg;
    if (error.error_code === REACT_ERROR_CODES.INVALID_SYNC_CODE) {
      msg = 'Invalid sync code. Please try again.';
    } else if (error.error_code === REACT_ERROR_CODES.INVALID_PARAM) {
      msg = error.error_message;
    }
    return msg;
  };
  if (
    !customer ||
    isNull(dashboardUserInfo) ||
    (customer.is_patient && isNull(careTeam)) ||
    isLoading
  ) {
    return <LoadingSpinner />;
  }

  const isCC =
    getPrivilegeById(dashboardUserInfo.privilege_id) === 'customer_care';

  let tabs = (
    !customer.is_patient
      ? ACCOUNT_DETAILS_ONLY
      : UNIFIED_FACILITY_ADMIN_ROLES.includes(
          dashboardUserInfo.facility_role_id as number
        ) || isCC
      ? SCHEDULER_ACCOUNT_DETAILS_TABS
      : ADMIN_ACCOUNT_DETAILS_TABS
  ) as TabConfig;

  return (
    <Container>
      <Helmet>
        <title>Ōmcare - Customer Details</title>
      </Helmet>
      <SideTabs
        selectedTab={selectedTab}
        tabs={tabs}
        sideBarTabSelection={sideBarTabSelection}
      />
      <Content>
        {isEqual(selectedTab, tabs.ACCOUNT_DETAILS) && customer.is_patient && (
          <OwnerDetailView
            customer={customer}
            isLoading={isLoading}
            setShowPinRequestModal={setShowPinRequestModal}
            isCC={isCC}
            title={tabs.ACCOUNT_DETAILS.text}
          />
        )}
        {isEqual(selectedTab, tabs.ACCOUNT_DETAILS) && !customer.is_patient && (
          <CaregiverDetailView
            customer={customer}
            title={tabs.ACCOUNT_DETAILS.text}
          />
        )}
        {isEqual(selectedTab, tabs.DEVICE_DETAILS) && (
          <DeviceDetails
            customer={customer}
            dashboardUserInfo={dashboardUserInfo}
            title={`${customer.name} - ${tabs.DEVICE_DETAILS.text}`}
            setShowSyncDeviceModal={setShowSyncDeviceModal}
            setUnassignConfirmationVisibility={
              setUnassignConfirmationVisibility
            }
          />
        )}
        {isEqual(selectedTab, tabs.CARE_TEAM) &&
          careTeam &&
          careTeam[customer.customer_id] && (
            <OwnerDetailCareTeam
              careTeam={careTeam[customer.customer_id]}
              handleAddPrimaryContact={handleAddPrimaryContact}
              title={`${customer.name} - ${tabs.CARE_TEAM.text}`}
              owner={customer}
            />
          )}
        {isEqual(selectedTab, tabs.MEDICATION_SCHEDULE) &&
          (UNIFIED_FACILITY_ADMIN_ROLES.includes(
            dashboardUserInfo.facility_role_id as number
          ) ||
            isCC) && (
            <MedicationScheduleTable
              customer={customer}
              careTeam={careTeam && careTeam[customer.customer_id]}
            ></MedicationScheduleTable>
          )}
      </Content>
      {show_sync_device_modal ? (
        <SyncDeviceModal
          ownerCustomerId={customer.customer_id}
          onClose={cancelModal}
        />
      ) : null}
      {showPinRequestModal ? (
        <PinRequestModal
          ownerCustomerId={customer.customer_id}
          onClose={cancelModal}
          ownerPin={currentOwnerPin}
          userEmail={dashboardUserInfo.email}
        />
      ) : null}
      {showUnassignConfirmation &&
      customer.device_id &&
      customer.device_serial_number ? (
        <UnassignDevice
          deviceId={customer.device_id}
          onCancel={() => setUnassignConfirmationVisibility(false)}
          onSuccess={() => setUnassignConfirmationVisibility(false)}
        />
      ) : null}
    </Container>
  );
}

export default CustomerDetail;
