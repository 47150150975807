import { toast } from 'react-toastify';
import { populateCustomers } from '../store/customers/actionCreators';
import { Owner } from '../store/customers/customerTypes';
import {
  populateDevices,
  updateDeviceStatus,
  updateSelectedDeviceStatus,
} from '../store/devices/actionCreators';
import { Device } from '../store/devices/deviceTypes';
import { wsConnected, wsDisconnected } from '../store/webSocket/actionCreators';
import {
  WebSocketStoreActionTypes,
  WSAction,
  WSEvent,
  WSPayload,
  WSStore,
  WSName,
  WSType,
  WSSource,
} from '../store/webSocket/webSocketTypes';
import { updateFacilityDeviceStatus } from '../store/facility/actionCreators';

let socketQueue: Array<any> = [];

const websocketHandleMsgResponse = (_store: WSStore, payload: WSPayload) => {
  if (payload.status !== 200) {
    console.log('WSS responded error: ', payload);
    toast.error(
      ` Error code ${payload.status} from request to ${payload.name}`
    );
    return;
  }

  switch (payload.name) {
    default:
      break;
  }
};

const websocketHandleMsgEvent = (store: WSStore, payload: WSPayload) => {
  switch (payload.name) {
    case WSName.DEVICE_STATUS:
      store.dispatch(
        updateDeviceStatus(payload.device_serial_number, payload.device_status)
      );
      break;

    default:
      break;
  }
};

const websocketHandleMsgCommand = (store: WSStore, payload: WSPayload) => {
  switch (payload.name) {
    case WSName.UPDATE_STATUS: {
      const {
        customers: { facilityCustomers },
        devices: { deviceList, selectedDevice },
      } = store.getState();
      const { connStatus, customer_id, serial_number } = payload;
      if (facilityCustomers) {
        store.dispatch(
          populateCustomers(
            (facilityCustomers || []).map((customer: Owner) => {
              const updatedCustomer = customer.customer_id === customer_id;
              if (updatedCustomer) {
                return {
                  ...customer,
                  is_online: connStatus === 'online' ? true : false,
                };
              }
              return customer;
            })
          )
        );
      }
      if (serial_number) {
        store.dispatch(
          populateDevices(
            (deviceList || []).map((device: Device) => {
              const isUpdatedDevice = device.serial_number === serial_number;
              if (isUpdatedDevice) {
                return {
                  ...device,
                  status: connStatus,
                };
              }
              return device;
            })
          )
        );
        store.dispatch(updateFacilityDeviceStatus(serial_number, connStatus));
        if (selectedDevice && selectedDevice.serial_number === serial_number) {
          store.dispatch(updateSelectedDeviceStatus(connStatus));
        }
      }
      return;
    }
    default:
      break;
  }
};

const websocketMiddleware = () => {
  let socket: any = null;
  const onOpen = (store: WSStore) => (event: WSEvent) => {
    store.dispatch(wsConnected(event.target.url));
    if (socketQueue.length) {
      socketQueue.forEach((message) => {
        socket.send(message);
      });
    }
  };

  const onClose = (store: WSStore) => () => {
    store.dispatch(wsDisconnected(store.host ? store.host : ''));
  };

  const onMessage = (store: WSStore) => (event: WSEvent) => {
    const payload = JSON.parse(event.data);
    console.log('received server message', payload);

    if (payload.source === WSSource.SERVER) {
      if (payload.type === WSType.RESPONSE) {
        websocketHandleMsgResponse(store, payload);
      } else if (payload.type === WSType.EVENT) {
        websocketHandleMsgEvent(store, payload);
      } else if (payload.type === WSType.COMMAND) {
        websocketHandleMsgCommand(store, payload);
      }
    }
  };

  // the middleware part of this function
  return (store: WSStore) => (next: any) => (action: WSAction) => {
    if (!action) {
      return console.log('ws action was null?');
    }
    switch (action.type) {
      case WebSocketStoreActionTypes.WS_CONNECT: {
        const { host, token } = action.payload;
        if (socket !== null) {
          socket.close();
        }

        if (!host) {
          console.error('WS_CONNECT: host must be provided');
          break;
        }

        if (!token) {
          console.error('WS_CONNECT: access token must be provided');
          break;
        }

        //TODO: set access token in the header
        let ws_uri = 'wss://' + host + '/?accessToken=' + token;

        // connect to the remote host
        socket = new WebSocket(ws_uri);

        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        console.log('opening socket', ws_uri);
        break;
      }
      case WebSocketStoreActionTypes.WS_DISCONNECT: {
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        console.log('websocket closed');
        break;
      }
      case WebSocketStoreActionTypes.WS_SEND: {
        const { msg } = action.payload;
        console.log('sending ws msg: ', msg);
        if (!msg.source) msg.source = WSSource.FACILITY_ADMIN;
        if (!msg.type) msg.type = WSType.COMMAND;
        if (!socket) {
          socketQueue.push(JSON.stringify(msg));
          return;
        }
        socket.send(JSON.stringify(msg));
        break;
      }
      default:
        return next(action);
    }
  };
};

export default websocketMiddleware();
